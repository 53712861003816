import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import Axios from 'axios';
import LS_SERVICE from './utils/localStorage'
import { ADMIN_PATIENT_LINK } from './constant';
import '../src/HomecareScreens/scss/newhomecare.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js';
import './assets/scss/style.scss';
import './assets/scss/ipd/index.scss';
import './assets/scss/ipd/patient_chart_summary.css';
import './assets/scss/ipd/medicine.scss';
import './assets/scss/ipd/patient-history.scss';
import './index.css';
import Store from './Store';
import { Provider } from 'react-redux';

const AppComponent = React.lazy(() => import('./App'));
Axios.interceptors.response.use(function (response) {
  return response;
}, function (res) {
  if (res?.response?.status === 401 || (res.status === 400 && LS_SERVICE.get('HEADER_TOKEN') === '')) {
    LS_SERVICE.clear();
    window.location.replace(ADMIN_PATIENT_LINK + "login");
  }
  return Promise.reject(res);
});

ReactDOM.render(
  <>
    <Provider store={Store}>
      <BrowserRouter>
        <Suspense fallback={
          <main>
            <div style={{ width: '100%', height: '100vh', background: 'rgba(255,255,255,.7)', borderRadius: '4px', overflow: "hidden", position: "absolute", zIndex: '999999' }} className="d-flex flex-column align-items-center justify-content-center">
              <img src='https://myhealthcare-his.s3.ap-south-1.amazonaws.com/Test/Assets/images/loader.gif' width='70' />
              <p><strong>Loading..</strong></p>
            </div>
          </main>
        }>
          <AppComponent />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </>
  , document.getElementById('root')
);
// serviceworker.unregister();
